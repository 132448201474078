.form_question_half {
  display: flex;
  justify-content: space-between;
}

.form_question_half > div {
  width: 60%;
}

.form_question_half > div:first-child {
  width: 37%;
}
