.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.data-grid {
  th {
    &.sortable {
      cursor: pointer;
    }

    .col-sort {
      &:before {
        margin-left: 3.5px;
        content: "\2191";
        opacity: .4;
      }

      &:after {
        content: "\2193";
        opacity: .4;
      }

      &.desc {
        &:after {
          opacity: 1;
        }
      }

      &.asc {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  td {
    vertical-align: middle;

    .btn-sm {
      padding: 3px 3px 6px 8px;
    }

    .btn-toolbar {
      display: inline-block;
      margin: 0 7px 0 0;
    }
  }
}

.pagination {
  align-items: center;
}
