.card__title {
    /* margin-bottom: 0; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-body {
    padding: 15px 30px 5px;
}


.btn-primary>a {
    color: #fff;
}

/* style for pagination */

.pagination {
    float: none;
    margin: 10px auto;
    justify-content: flex-end;
}

.page-link {
    margin: 0 3px;
    border:none;
    border-radius: 50%;
    background-color: #eee;
}

.page-item.active .page-link   {
    border-radius: 50%; 
}

/* table style */

.table {
    border: none;
}

.react-bootstrap-table {
    position: relative;
}

.my-table {
    table-layout: auto;
}