.dialog {
  width: 100%;
  margin-bottom: 20px;

  h3 {
    padding: 0 0 8px;
    margin: 0 0 10px;
    border-bottom: 1px solid #f2f4f7;
    color: #646777;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
  }

  .record {
    .messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .question, .answer {
        position: relative;

        .photo, .initials {
          position: absolute;
          top: 10px;
          left: 0;
          width: 40px;
          height: 40px;
          min-width: 40px;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
          }
        }

        .initials {
          background: #d7cade;
          color: #646777;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 42px;
        }

        .body {
          background: #f7f8fa;
          padding: 10px 40px 10px 20px;
          border-radius: 0 40px 40px 20px;
          margin: 10px 0 10px 50px;
          max-width: 870px;

          .name {
            padding: 0;
            margin: 0 0 5px;
            font-size: 13px;
            font-weight: 500;

            .bull {
              display: inline-block;
              padding: 0 10px;
              color: #b7b7b7;
            }

            .date {
              color: #b7b7b7;
              font-weight: normal;
            }
          }

          .message {
            color: #646777;
            font-size: 12px;
            line-height: 1.6;

            ol, ul {
              margin: 0;
              padding: 10px 0 0 30px;
            }

            .video {
              position: relative;
              padding-bottom: 56.25%;
              padding-top: 25px;
              height: 0;

              iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .answer {
        .body {
          background: #f2f5ff;
        }
      }
    }
  }
}
